import React, { useState, useEffect } from "react";
import Header from "./Common/Header";
import Navbar from "./Common/Navbar";
// import Footer from "./Common/Footer";
// import Footer from "./Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const From = () => {
  const genders = [
    { value: "", label: "Gender" },
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
    { value: "other", label: "Other" },
  ];

  const courses = [
    { value: "", label: "Select a course" },
    { value: "front-end", label: "Front-end" },
    { value: "back-end", label: "Back-end" },
    { value: "full-stack", label: "Full Stack" },
  ];

  const paymentmode = [
    { value: "", label: "Select Payment Mode" },
    { value: "one-Time-pay-out", label: "One-Time-Pay-Out" },
    { value: "emi", label: "Emi" },
  ];

  const emiOptions = [
    { value: "", label: "Select Payment Emi" },
    { value: "3-months", label: "3 Months" },
    { value: "6-months", label: "6 Months" },
  ];

  const paymentmethod = [
    { value: "", label: "Select Payment Method" },
    { value: "Upi", label: "UPI" },
    { value: "Cash", label: "Cash" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [selectedPaymentMode, setSelectedPaymentMode] = useState("");
  const [adjustedAmount, setAdjustedAmount] = useState("");
  const [selectedEmiDuration, setSelectedEmiDuration] = useState("");
  const [emiAmounts, setEmiAmounts] = useState([]);
  const [checkboxes, setCheckboxes] = useState([]);

  const totalAmount = watch("totalamount");

  const handlePaymentModeChange = (event) => {
    const paymentMode = event.target.value;
    setSelectedPaymentMode(paymentMode);

    if (paymentMode === "one-Time-pay-out" && totalAmount) {
      const discountedAmount = parseFloat(totalAmount) * 0.8;
      setAdjustedAmount(discountedAmount.toFixed(2)); // Store the discounted amount
    } else {
      setAdjustedAmount(""); // Clear adjusted amount if not a one-time payout
    }
  };

  const handleEmiDurationChange = (event) => {
    const duration = event.target.value;
    setSelectedEmiDuration(duration);

    if (duration && totalAmount) {
      const months = parseInt(duration.split("-")[0]);
      const monthlyAmount = (parseFloat(totalAmount) / months).toFixed(2);
      setEmiAmounts(Array(months).fill(monthlyAmount));
    } else {
      setEmiAmounts([]);
    }
  };

  useEffect(() => {
    if (emiAmounts.length > 0) {
      // Initialize checkboxes based on the length of emiAmounts
      setCheckboxes(new Array(emiAmounts.length).fill(false));
    } else {
      // Clear checkboxes if no EMI amounts are provided
      setCheckboxes([]);
    }
  }, [emiAmounts]);

  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  console.log(checkboxes,12222222)

  const onSubmit = async (data) => {
    // Collect checked EMI amounts
    const checkedEmiData = emiAmounts
      .map((amount, index) => ({
        [`emi${index + 1}`]: checkboxes[index] ? amount : null,
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});



    const formData = {
      ...data,
      ...checkedEmiData, // Only include checked EMI amounts
      emiduration: selectedEmiDuration, // Add EMI duration to the data
      emiAmounts, // Send emiAmounts array directly
      checkboxes
    };

    console.log(formData);
    try {
      const res = await axios.post(
        "https://siw-student-from-fh5m.onrender.com/api/siw/service_form",
        formData
      );
      console.log(res.data);
      const datasets = res.data.status;
      if (datasets === 201) {
        toast.success("Form Registration is successful", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        reset();
        navigate("/tables"); // Navigate to home page on successful registration
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 401) {
          toast.error("Server Error");
        } else {
          toast.error(error.response.data.message);
        }
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      <Navbar />
      <Header />
      <div className="clearfix"></div>
      <div className="content-wrapper">
        <div className="container-fluid">
          <div className="row mt-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="card-title">Student Form Detail</div>
                  <form
                    className="border-top-3"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row mt-3">
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-1">Name</label>
                          <input
                            type="text"
                            className="input-form form-control-input"
                            id="input-1"
                            placeholder="Enter Your Full Name"
                            {...register("name", {
                              required: "Name is required",
                              minLength: {
                                value: 5,
                                message:
                                  "Name should be at least 5 characters long",
                              },
                              maxLength: {
                                value: 25,
                                message:
                                  "Name should be at most 25 characters long",
                              },
                              pattern: {
                                value: /^[a-zA-Z\s]+$/,
                                message: "Name should contain only alphabets",
                              },
                            })}
                          />
                          {errors.name && (
                            <div className="text-danger">
                              {errors.name.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-1">Mobile</label>
                          <input
                            type="number"
                            className="input-form form-control-input"
                            id="input-1"
                            placeholder="Enter Your Mobile Number"
                            {...register("studentNumber", {
                              required: "Mobile is required",
                              minLength: {
                                value: 10,
                                message: "Mobile should be 10 digits long",
                              },
                              maxLength: {
                                value: 10,
                                message: "Mobile should be 10 digits long",
                              },
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Mobile should contain only numbers",
                              },
                            })}
                            style={{ backgroundColor: "#f0f8ff" }}
                          />
                          {errors.studentNumber && (
                            <div className="text-danger">
                              {errors.studentNumber.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-3">Alternate Mobile</label>
                          <input
                            type="number"
                            className="input-form form-control-input"
                            id="input-3"
                            placeholder="Enter Your Alternate Mobile Number"
                            {...register("altnumber")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Email</label>
                          <input
                            type="email"
                            className="input-form form-control-input"
                            id="input-2"
                            placeholder="Enter Your Email Address"
                            {...register("email", {
                              required: "Email is required",
                              minLength: {
                                value: 5,
                                message:
                                  "Email should be at least 5 characters long",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Email should be at most 50 characters long",
                              },
                              pattern: {
                                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                message: "Invalid email format",
                              },
                            })}
                          />
                          {errors.email && (
                            <div className="text-danger">
                              {errors.email.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Gender</label>
                          <select
                            className="input-form"
                            {...register("gender", {
                              required: "Gender is required",
                              minLength: {
                                value: 1,
                                message: "Gender should be selected",
                              },
                            })}
                          >
                            {genders.map((gender) => (
                              <option key={gender.value} value={gender.value}>
                                {gender.label}
                              </option>
                            ))}
                          </select>
                          {errors.gender && (
                            <div className="text-danger">
                              {errors.gender.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Previous Education</label>
                          <input
                            type="text"
                            className="input-form form-control-input "
                            id="input-2"
                            placeholder="Enter Your Previous Education"
                            {...register("previousEducation", {
                              required: "Previous Education is required",
                              minLength: {
                                value: 5,
                                message:
                                  "Previous Education should be at least 5 characters long",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "Previous Education should be at most 50 characters long",
                              },
                            })}
                          />
                          {errors.previousEducation && (
                            <div className="text-danger">
                              {errors.previousEducation.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Target Education</label>
                          <input
                            type="text"
                            className="input-form form-control-input"
                            id="input-2"
                            placeholder="Enter Your Target Education"
                            {...register("educationTarget")}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Address</label>
                          <input
                            type="text"
                            className="input-form form-control-input"
                            id="input-2"
                            placeholder="Enter Your Address"
                            {...register("address", {
                              required: "Address is required",
                              minLength: {
                                value: 5,
                                message:
                                  "Address should be at least 5 characters long",
                              },
                              maxLength: {
                                value: 100,
                                message:
                                  "Address should be at most 100 characters long",
                              },
                             
                            })}
                          />
                          {errors.address && (
                            <div className="text-danger">
                              {errors.address.message}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Courses</label>
                          <select
                            className="input-form"
                            {...register("courses", {
                              required: "Courses is required",

                            })}
                          >
                            {courses.map((course) => (
                              <option key={course.value} value={course.value}>
                                {course.label}
                              </option>
                            ))}
                          </select>
                          {errors.courses && (
                            <div className="text-danger">
                              {errors.courses.message}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Message
                          </label>
                          <textarea
                            className="input-form"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            {...register("message", {
                              required: "Message is required",
                              minLength: {
                                value: 5,
                                message:
                                  "Message should be at least 5 characters long",
                              },
                              maxLength: {
                                value: 200,
                                message:
                                  "Message should be at most 200 characters long",
                              },
                              
                            })}
                          />
                          {errors.message && (
                            <div className="text-danger">
                              {errors.message.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-title mt-4">
                      Student Payment Structure Method
                    </div>
                    <div className="border-top-3"></div>

                    <div className="row mt-3">
                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Total Amount</label>
                          <input
                            type="number"
                            className="input-form form-control-input"
                            id="input-2"
                            placeholder="Enter Your Address"
                            {...register("totalamount")}
                          />
                         
                        </div>
                      </div>

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="input-2">Payment Mode</label>
                          <select
                            className="input-form"
                            {...register("paymentmode")}
                            onChange={handlePaymentModeChange}
                          >
                            {paymentmode.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.paymentmode && (
                            <div className="text-danger">
                              {errors.paymentmode.message}
                            </div>
                          )}
                        </div>
                      </div>

                      {selectedPaymentMode === "one-Time-pay-out" && (
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="input-3">
                              Amount (After 20% Off)
                            </label>
                            <input
                              type="number"
                              className="input-form text-white"
                              value={adjustedAmount} // Display the discounted amount here
                              readOnly // Make this field read-only if it should not be editable
                              {...register("adjustedAmount")}
                            />
                          </div>
                        </div>
                      )}
                      {selectedPaymentMode === "emi" && (
                        <>
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="emiduration">
                                Select EMI Duration
                              </label>
                              <select
                                className="input-form"
                                {...register("emiduration", {
                                  required: "EMI duration is required",
                                })}
                                onChange={handleEmiDurationChange}
                              >
                                {emiOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              {errors.emiduration && (
                                <div className="text-danger">
                                  {errors.emiduration.message}
                                </div>
                              )}
                            </div>
                          </div>

                          {emiAmounts.length > 0 && (
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>EMI Breakdown</label>
                                <div className="row">
                                  {emiAmounts.map((amount, index) => (
                                    <div
                                      className="col-lg-6 col-sm-12"
                                      key={index}
                                    >
                                      <div className="form-check">
                                        <input
                                          type="checkbox"
                                          id={`checkbox${index}`}
                                          checked={checkboxes[true]}
                                          onChange={() =>
                                            handleCheckboxChange(index)
                                          }
                                          className="form-check-input"
                                        />
                                        <label
                                          htmlFor={`checkbox${index}`}
                                          className="form-check-label"
                                        >
                                          Month {index + 1}
                                        </label>
                                        <input
                                          type="text"
                                          className="input-form mb-2"
                                          value={amount}
                                          readOnly
                                          disabled={!checkboxes[index]}
                                          {...register(`emi${index + 1}`)}
                                        />
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      )}

                      <div className="col-lg-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="emiduration">Payment Method</label>
                          <select
                            id="paymentMode"
                            className="input-form"
                            // value={selectedPaymentMode}
                            onChange={handlePaymentModeChange}
                            {...register("paymentmethod")}
                          >
                            {paymentmethod.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.paymentmethod && (
                            <div className="text-danger">
                              {errors.paymentmethod.message}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-light px-5 form-control-input form-control-re"
                      >
                        <i className="icon-lock"></i> Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!--End Row--> */}

          {/* <!--start overlay--> */}
          <div className="overlay toggle-menu"></div>
          {/* <!--end overlay--> */}
        </div>
        {/* <!-- End container-fluid--> */}
      </div>
      {/* <!--End content-wrapper--> */}
      {/* <!--Start Back To Top Button--> */}
      <a href="javaScript:void();" className="back-to-top">
        <i className="fa fa-angle-double-up"></i>
      </a>
      {/* <!--End Back To Top Button--> */}
    </>
  );
};

export default From;
