import React, { useEffect, useState } from "react";
import Header from "./Common/Header";
import Navbar from "./Common/Navbar";
// import Footer from "./Common/Footer";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Icons
import {  FaEdit, FaTrashAlt } from "react-icons/fa";

// Images
import image1 from "../../src/assets/images/student-img/images.jpeg";
import axios from "axios";

const Tables = () => {
  // const [formData, setFormData] = useState({
  //   totalamount: "",
  //   paymentmode: "",
  //   adjustedAmount: "",
  //   emiOptions: [],
  //   emiAmounts: [],
  //   selectedPaymentMode: "",
  //   // Add other fields as needed
  // });

  // const handleFormSubmit = (data) => {
  //   setFormData({
  //     totalamount: data.totalamount,
  //     paymentmode: data.paymentmode,
  //     adjustedAmount: data.adjustedAmount,
  //     emiOptions: data.emiOptions,
  //     emiAmounts: data.emiAmounts,
  //     selectedPaymentMode: data.selectedPaymentMode,
  //     // Set other fields as needed
  //   });
  // };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [datass, setDatass] = useState(null);
  const [viewData, setViewData] = useState(null);

  // Update item only when viewData changes
  useEffect(() => {
    if (viewData?.checkboxes) {
      setCheckboxes(viewData?.checkboxes);
    }
  }, [viewData]); // This effect runs only when viewData changes


  const [checkboxes, setCheckboxes] = useState([]);

  const handlechangeTables = async () => {
    try {
      const res = await axios.get(
        "https://siw-student-from-fh5m.onrender.com/api/siw/service_form/get",
        {
          headers: { "ngrok-skip-browser-warning": "69420" },
        }
      );

      setDatass(res?.data);
    } catch (error) {
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  useEffect(() => {
    handlechangeTables();
  }, []);

  const handleViewClick = (row) => {
    setViewData(row);
  };


  const handleCheckboxChange = (index) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
  };

  // Update Api

  const onSubmit = async (data) => {
    let id = viewData._id;

    const checkedEmiData = viewData.emiAmounts
      .map((amount, index) => ({
        [`emi${index + 1}`]: checkboxes[index] ? amount : null,
      }))
      .reduce((acc, cur) => ({ ...acc, ...cur }), {});

    const formData = {
      ...data,
      ...checkedEmiData,
      // ...viewData.emiAmounts,
      checkboxes

    };

    try {
      const res = await axios.put(
        `https://siw-student-from-fh5m.onrender.com/api/siw/service_form/${id}`,
        formData
      );
      const datasets = res.data;
      // const datasets = res.data;
      console.log(datasets, 1222);
      toast.success("Update is successfully", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      reset();
      
      handlechangeTables();
      navigate("/tables");
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  // Delete Api

  const handleChangeDet = async (row) => {
    let id = row._id;

    try {
      const res = await axios.delete(
        `https://siw-student-from-fh5m.onrender.com/api/siw/service_form/${id}`
      );
      console.log(res.data);
      const datasets = res.data.message;

      if (datasets === "User deleted") {
        toast.success("Delete is successfully", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        handlechangeTables();
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  

  return (
    <>
      <div className="content-wrapper">
        <div className="container-flu">
          <Header />
          <Navbar />
          {/* Table Start */}
          <section id="table">
            <div class="container">
              <div class="row">
                <div class="col-lg-12">
                  <div class="table-title">
                    <h3>Student Details</h3>
                  </div>

                  <table className="table text-center">
                    <thead>
                      <tr>
                        <th scope="col text-dark">
                          <span className="text-dark">#</span>
                        </th>
                        <th scope="col text-dark">
                          <span className="text-dark">Name</span>
                        </th>
                        <th scope="col">
                          <span className="text-dark">Email</span>
                        </th>
                        <th scope="col">
                          <span className="text-dark">Student Number</span>
                        </th>
                        <th scope="col">
                          <span className="text-dark">Education Target</span>
                        </th>
                        <th scope="col">
                          <span className="text-dark">Previous Education</span>
                        </th>
                        {/* <th scope="col">Address</th> */}
                        <th scope="col">
                          <span className="text-dark">Status</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* Map over the data array to generate table rows */}
                      {datass?.map((row, index) => (
                        <tr key={index} className="cellspace text-center">
                          <td cclassName="fonst-size-14">
                            <span>{index + 1}</span>
                          </td>
                          <td className="fonst-size-14">
                            <span>{row.name}</span>
                          </td>
                          <td className="fonst-size-14">
                            <span>{row.email}</span>
                          </td>
                          <td className="fonst-size-14">
                            <span>{row.studentNumber}</span>
                          </td>
                          <td className="fonst-size-14">
                            <span>{row.educationTarget}</span>
                          </td>
                          <td className="fonst-size-14">
                            <span>{row.previousEducation}</span>
                          </td>
                          <td className="d-flex justify-content-center">
                            <button
                              className="btn text-success ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrops"
                              onClick={() => handleViewClick(row)}
                            >
                              <FaEdit />
                            </button>
                            <button
                              className="btn text-danger ms-2"
                              onClick={() => handleChangeDet(row)}
                            >
                              <FaTrashAlt />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* Table End */}

      {/* <!-- Edit Modal --> */}
      <div
        class="modal fade"
        id="staticBackdrops"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabels"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-dark fw-bold"
                id="staticBackdropLabels"
              >
                Student form
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body ">
              <div class="d-flex justify-content-center">
                <img class="rounded-circle mb-4" src={image1} alt="Loading" />
              </div>
              {/* <div className="row mt-3"> */}
              {/* <div className="col-lg-12 col-lg-6"> */}
              <div className="card ">
                <div className="card-body">
                  <div className="card-title">Student Form Detail</div>

                  {viewData == null ? null : (
                    <form
                      className="border-top-3"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row mt-3">
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-1">Name</label>
                            <input
                              type="text"
                              className="form-control form-control-input"
                              id="input-1"
                              defaultValue={viewData.name}
                              {...register("name", {
                                required: "Name is required",
                              })}
                            />
                            {errors.name && (
                              <div className="text-danger">
                                {errors.name.message}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-3">Mobile</label>
                            <input
                              type="number"
                              className="form-control form-control-input"
                              id="input-3"
                              defaultValue={viewData.studentNumber}
                              {...register("studentNumber", {
                                required: "Mobile is required",
                              })}
                            />
                            {errors.studentNumber && (
                              <div className="text-danger">
                                {errors.studentNumber.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-2">Email</label>
                            <input
                              type="email"
                              className="form-control form-control-input"
                              id="input-2"
                              defaultValue={viewData.email}
                              {...register("email", {
                                required: "Email is required",
                              })}
                            />
                            {errors.email && (
                              <div className="text-danger">
                                {errors.email.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div class="form-group">
                            <label for="input-2">Gender</label>
                            <br />

                            <select
                              {...register("gender")}
                              className="form-control form-control-input"
                              defaultValue={viewData.gender}
                            >
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                              <option value="other">other</option>
                            </select>
                            {errors.gender && (
                              <div className="text-danger">
                                {errors.gender.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-2">Previous Education</label>
                            <input
                              type="text"
                              className="form-control form-control-input"
                              id="input-2"
                              defaultValue={viewData.previousEducation}
                              {...register("previousEducation", {
                                required: "Previous Education is required",
                              })}
                            />
                            {errors.previousEducation && (
                              <div className="text-danger">
                                {errors.previousEducation.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-2"> Target Education</label>
                            <input
                              type="text"
                              className="form-control form-control-input"
                              id="input-2"
                              defaultValue={viewData.educationTarget}
                              {...register("educationTarget")}
                            />
                            
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label for="input-2">Address</label>
                            <input
                              type="text"
                              className="form-control form-control-input"
                              id="input-2"
                              defaultValue={viewData.address}
                              {...register("address", {
                                required: "Address is required",
                              })}
                            />
                            {errors.address && (
                              <div className="text-danger">
                                {errors.address.message}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div class="mb-3">
                            <label
                              for="exampleFormControlTextarea1"
                              class="form-label"
                            >
                              Message
                            </label>
                            <textarea
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              defaultValue={viewData.message}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="card-title mt-4">
                        Student Payment Structure Method
                      </div>
                      <div className="border-top-3"></div>

                      <div className="row mt-3">
                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="total-amount">Total Amount</label>
                            <input
                              type="number"
                              className="form-control form-control-input"
                              id="total-amount"
                              defaultValue={viewData.totalamount}
                              readOnly
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="payment-mode">Payment Mode</label>
                            <input
                              type="text"
                              className="form-control"
                              id="payment-mode"
                              value={viewData.paymentmode}
                              readOnly
                            />
                          </div>
                        </div>

                        {viewData.paymentmode === "one-Time-pay-out" && (
                          <div className="col-lg-6 col-sm-12">
                            <div className="form-group">
                              <label htmlFor="adjusted-amount">
                                Amount (After 20% Off)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="adjusted-amount"
                                value={viewData.adjustedAmount}
                                readOnly
                              />
                            </div>
                          </div>
                        )}

                        {viewData.paymentmode === "emi" && (
                          <>
                            <div className="col-lg-6 col-sm-12">
                              <div className="form-group">
                                <label htmlFor="emi-duration">
                                  Select EMI Duration
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="emi-duration"
                                  defaultValue={viewData.emiduration}
                                  readOnly
                                />
                              </div>
                            </div>

                            {/* {viewData.emiAmounts.length > 0 && (
                            <div className="col-lg-12">
                              <div className="form-group">
                                <label>EMI Breakdown</label>
                                <div className="row">
                                  {viewData.emiAmounts.map((amount, index) => (
                                    <div
                                      className="col-lg-6 col-sm-12"
                                      key={index}
                                    >
                                      <div className="form-check">
                                        <input
                                          type="text"
                                          className="form-control mb-2"
                                          value={amount}
                                          readOnly
                                        />
                                        <label className="form-check-label">
                                          Month {index + 1}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )} */}

                            {viewData.emiAmounts.length > 0 && (
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label>EMI Breakdown</label>
                                  <div className="row">
                                    {viewData.emiAmounts.map(
                                      (amount, index) => (
                                        <div
                                          className="col-lg-6 col-sm-12"
                                          key={index}
                                        >
                                          <div className="form-check">
                                            <input
                                              type="checkbox"
                                              id={`checkbox${index}`}
                                              checked={
                                                checkboxes[index] || false
                                              }
                                              onChange={() =>
                                                handleCheckboxChange(index)
                                              }
                                              className="form-check-input"
                                            />
                                            <label
                                              htmlFor={`checkbox${index}`}
                                              className="form-check-label"
                                            >
                                              Month {index + 1}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control mb-2"
                                              value={amount}
                                              readOnly
                                              disabled={!checkboxes[index]}
                                              {...register(`emi${index + 1}`)}
                                            />
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-light px-5 form-control-input form-control-re"
                        >
                          <i className="icon-lock"></i> Submit
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-danger"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="submit" class="btn btn-success">
                Save Changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* End Edit Modal */}
    </>
  );
};
export default Tables;
