import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "./Common/Header";
import Navbar from "./Common/Navbar";
import Footer from "./Common/Footer";
import { toast } from "react-toastify";


const Dashboard = () => {

  // state

  const [datass, setDatass] = useState(null);

  // Api Functions

  const handlechangeTables = async () => {
    try {
      const res = await axios.get(
        "https://siw-student-from-fh5m.onrender.com/api/siw/service_form/get",
        {
          headers: { "ngrok-skip-browser-warning": "69420" },
        }
      );

      setDatass(res?.data);
    } catch (error) {
      if (error.response) {
        toast.error(error?.response?.data?.message);
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection");
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  useEffect(() => {
    handlechangeTables();
  }, []);


 
  return (
    <>
      <Navbar />
      <Header />
      <div className="clearfix"></div>
      <div className="content-wrapper">
        <div className="container-fluid">
          {/* <!--Start Dashboard Content--> */}
          <div className="card mt-3">
            <div className="card-content ">
              <div className="row row-group m-0">
                <div className="col-12 col-lg-6 col-xl-3 ">
                  <div className="card-body">
                    <h5 className="text-white mb-0">
                      <span>23</span>
                      <span className="float-right">
                        <i className="fa fa-shopping-cart"></i>
                      </span>
                    </h5>
                    <div className="progress my-3" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        style={{ width: "55%" }}
                      ></div>
                    </div>
                    <p className="mb-0  small-font boxColor">
                      Total Orders
                      <span className="float-right hight-tred">
                        +4.2% <i className="zmdi zmdi-long-arrow-up"></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3 ">
                  <div className="card-body">
                    <h5 className="text-white mb-0">
                      <span>34</span>
                      <span className="float-right">
                        <i className="fa fa-usd"></i>
                      </span>
                    </h5>
                    <div className="progress my-3" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        style={{ width: "55%" }}
                      ></div>
                    </div>
                    <p className="mb-0  small-font boxColor">
                      Total Revenue
                      <span className="float-right hight-tred">
                        +1.2% <i className="zmdi zmdi-long-arrow-up"></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3 ">
                  <div className="card-body">
                    <h5 className="text-white mb-0">
                      <span>334</span>
                      <span className="float-right">
                        <i className="fa fa-eye"></i>
                      </span>
                    </h5>
                    <div className="progress my-3" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        style={{ width: "55%" }}
                      ></div>
                    </div>
                    <p className="mb-0  small-font boxColor">
                      Visitors
                      <span className="float-right hight-tred">
                        +5.2% <i className="zmdi zmdi-long-arrow-up"></i>
                      </span>
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-3 ">
                  <div className="card-body">
                    <h5 className="text-white mb-0">
                      <span>455</span>
                      <span className="float-right">
                        <i className="fa fa-envira"></i>
                      </span>
                    </h5>
                    <div className="progress my-3" style={{ height: "3px" }}>
                      <div
                        className="progress-bar"
                        style={{ width: "55%" }}
                      ></div>
                    </div>
                    <p className="mb-0 small-font boxColor">
                      Messages
                      <span className="float-right hight-tred">
                        +2.2% <i className="zmdi zmdi-long-arrow-up"></i>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        

          {/* Recent Order Tables  */}

          <div className="row">
            <div className="col-12 col-lg-12">
              <div className="card">
                <div className="card-header ">
                  Recent Tables
                  <div className="card-action"></div>
                </div>
                <div className="table-responsive">
                  <table className="table align-items-center table-flush table-borderless table-dashbord text-white ">
                    <thead>
                      <tr className="border-top-3">
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Student Number</th>
                        <th scope="col">Education Target</th>
                        <th scope="col">Previous Education</th>
                        <th scope="col">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      {datass?.slice(0, 3).map((row, index) => (
                        <tr className="border-top-3" key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{row.name}</td>
                          <td>{row.email}</td>
                          <td>{row.studentNumber}</td>
                          <td>{row.educationTarget}</td>
                          <td>{row.previousEducation}</td>
                          <td>{row.address}</td>

                        </tr>
                      ))}
                      
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* Recent Order Tables */}
          {/* <!--End Dashboard Content--> */}
        </div>
      </div>

      {/* <!--Start Back To Top Button--> */}
      <a href="javaScript:void();" className="back-to-top">
        <i className="fa fa-angle-double-up"></i>
      </a>
      {/* <!--End Back To Top Button--> */}
    </>
  );
};
export default Dashboard;
