import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/images/timeline/1.png";

import { useAuth } from "./AuthContext/AuthContext";


const Login = ({ setLoginUser }) => {
  const { handlelogin } = useAuth();

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const login = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://siw-student-from-fh5m.onrender.com/api/siw/login",
        user
      );

      if (res.data.status === 200) {
        // Assuming the response contains a token on successful login
        const { token } = res.data;
        localStorage.setItem("authToken", token); // Save the token in local storage
        
        toast.success("Login successful", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        navigate("/home"); // Redirect to home page
        handlelogin()
      } else {
        toast.error(res.data.message || "Login failed. Please try again.");
      }
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        if (statusCode === 400) {
          toast.error("Invalid email or password. Please try again.");
        } else if (statusCode === 404) {
          toast.error("User not found. Please check your email.");
        } else {
          toast.error(error.response.data.message || "An error occurred");
        }
      } else if (error.request) {
        toast.error("Network Error: Please check your internet connection.");
      } else {
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <>
      <section className="h-100 gradient-form background-color">
        <div className="container py-2 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col-xl-10">
              <div className="card rounded-3 text-black">
                <div className="row g-0">
                  <div className="col-lg-6 background-color-box">
                    <div className="card-body mx-md-4">
                      <div className="text-center">
                        <img src={Logo} alt="logo" className="img-log"/>
                        <h4 className="mt-1 mb-5 pb-1 text-color">
                        Intelligence Educator
                        </h4>
                      </div>

                      <form onSubmit={login}>
                        <p className="text-color">Please login to your account</p>
                        <label className="form-label text-color" htmlFor="form2Example11">
                          Email:
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            type="email"
                            id="form2Example11"
                            className="form-control"
                            onChange={handleChange}
                            name="email"
                            value={user.email}
                            required
                          />
                        </div>
                        <label className="form-label text-color" htmlFor="form2Example22">
                          Password:
                        </label>
                        <div className="form-outline inputboder mb-4">
                          <input
                            type="password"
                            id="form2Example22"
                            className="form-control input"
                            onChange={handleChange}
                            name="password"
                            value={user.password}
                            required
                          />
                        </div>

                        <div className="text-center pt-1 mb-5 pb-1">
                          <button
                            className="btn btn-primary btn-block fa-lg gradient-custom-2 mb-3 fontfamily"
                            type="submit"
                          >
                            Sign in 
                          </button>
                        </div>

                        <div className="d-flex align-items-center justify-content-center pb-4">
                          <p className="mb-0 me-2 text-color">Don't have an account?</p>
                          <button type="button" className="btn fontfamily ml-4">
                            <Link
                              to="/register"
                              style={{
                                color: "#d8363a",
                                textDecoration: "none",
                              }}
                            >
                              Create new
                            </Link>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-lg-6 d-flex align-items-center gradient-custom-2">
                    <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                      <h4 className="mb-4">We are more than just a company</h4>
                      <p className="small mb-0">
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
